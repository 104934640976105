import React, { useRef, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import api from "../../service/api";
import * as Yup from "yup";
import { Form } from "@unform/web";
import InputMaks from "../../components/InputMaks/index";
import { toast } from "react-toastify";
import "./style.css";

export default function ModalAdd({
  modalVisible,
  closeModal,
  fetchData,
  phone,
}) {
  const formAdd = useRef(null);

  async function adicionarNum(data) {
    formAdd.current.setErrors({});
    try {
      let schema = Yup.object().shape({
        numero: Yup.string().required("Numero é obrigatório"),
      });

      await schema.validate(data, { abortEarly: false });

      data.numero = data.numero.toString().replace(/\D/im, "");

      if (phone) {
        const response = await api.put(`/v1/numeros-proibidos`, {
          _id: phone._id,
          numero: data.numero,
        });
        if (!response.data.success) {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return;
        }
        toast.success("Sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        const response = await api.post(`/v1/numeros-proibidos`, data);
        if (!response.data.success) {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return;
        }
        toast.success("Sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      closeModal();
      fetchData();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAdd.current.setErrors(validationErrors);
      }
    }
  }

  useEffect(() => {
    if (phone) {
      setTimeout(function () {
        if (formAdd.current && formAdd.current.setData)
          formAdd.current.setData({ numero: phone?.numero });
      }, 100);
    }
  }, [phone]);

  return (
    <Modal
      open={modalVisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <Form ref={formAdd} onSubmit={adicionarNum}>
        <div id="config">
          <div className="mr-5 mb-3">
            <h3 className="colorBackground mb-3">Editar Número</h3>
          </div>
          <div className="container-fluid">
            <div className="row">
              <InputMaks
                type="text"
                name="numero"
                mask="(99) 99999-9999"
                id="modal"
                className="inputPadrao"
              />
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Salvar
          </button>
        </div>
      </Form>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    minWidth: "100px",
    width: "500px",
  },
};
