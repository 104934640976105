import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../service/api";
import { login } from "../../service/auth";
import Input from "../../components/Input";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import "./styles.css";

export default function Login({ context, history }) {
  const formRef = useRef(null);

  async function handleLogin(data) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Email inválido")
          .required("Email é obrigatório"),
        password: Yup.string().required("Senha é obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });
      const response = await api.post("/login", data);
      if (!response.data.success) {
        toast.error(response.data.err, { position: toast.POSITION.TOP_CENTER });
        return;
      }
      login(response.data.token);
      toast.success("Login feito com sucesso!", {
        position: toast.POSITION.TOP_CENTER,
      });
      context.setauthenticated(true);
      history.push("/");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function LoginViaURL(){
    const dados = new URLSearchParams(window.location.search);
    if (dados.get("email") && dados.get("password")) {
      await handleLogin({
        email: dados.get("email"),
        password: dados.get("password"),
      });
      await context.updateState();
    }
  }

  useEffect(() => {
    if (context && window.location.search) {
      LoginViaURL();
    }
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="login-container">
        <Form ref={formRef} onSubmit={handleLogin}>
          <div className="col-12">
            <div className="text-center">
              <h3 className="mb-3">Acesso aos Best Message</h3>
            </div>
          </div>
          <Input
            type="email"
            className="inputPadrao mb-2"
            autoComplete="email"
            name="email"
            label="Email"
          />
          <Input
            type="password"
            className="inputPadrao mb-2"
            name="password"
            autoComplete="current-password"
            label="Senha"
          />
          <button type="submit"> Entrar</button>
          <Link to="/esqueci-senha" className="labelSmall pt-4 mt-2">
            {" "}
            Esqueci minha senha
          </Link>
          <Link to="/registrar-se" className="labelSmall mt-2">
            {" "}
            Criar uma conta
          </Link>
        </Form>
      </div>
    </>
  );
}
