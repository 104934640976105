import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { GlobalState } from "../../state/state";
import * as Yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import InputMask from "../../components/InputMaks";
import api from "../../service/api";

export default function ModalAddEditContatos({
  modalvisible,
  contato,
  closeModal,
  context,
}) {
  const formRef = useRef(null);
  const [zaps, setzaps] = useState([]);
  const [zap, setzap] = GlobalState("zap");

  async function handleSalvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required("Nome é obrigatório"),
        telefone: Yup.string().required("Telefone é obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });

      if (!zap) {
        toast.error("Selecione um número", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      data.zap = zap._id;
      data.telefone = data.telefone.toString().replace(/\D/im, "");

      const response = await api.post("/v1/contatos", data);

      if (response.data.success) {
        toast.success("Contato salvo com sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        closeModal();
      } else {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchData() {
    if (context && context.zaps) {
      setzaps(context.zaps);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <Modal
      open={modalvisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-9 col-md-9">
            {contato && contato.telefone ? (
              <h3>Editar contato</h3>
            ) : (
              <h3>Adicionar novo contato</h3>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6">
            <label>Número vinculado</label> <br />
            <label>
              {zap ? zap.telefone : "Sem número vinculado"}
            </label>
          </div>
          <div className="col-sm-12 col-md-6">
            {(zaps.length > 2 || !zap) && (
              <>
                <label>Selecionar Zap</label>
                <Select
                  placeholder="Selecione um número"
                  value={zap}
                  defaultValue={zap}
                  onChange={(e) => setzap(e)}
                  options={zaps}
                />
              </>
            )}
          </div>
        </div>
        <hr />
        <Form className="mt-3" ref={formRef} onSubmit={handleSalvar}>
          <div className="row">
            <div className="col-sm-12 col-md-6 mt-2 mb-2">
              <Input
                type="text"
                className="input-form"
                name="nome"
                placeholder="Digite o nome do contato"
                label="Nome do contato"
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-2 mb-2">
              <InputMask
                type="text"
                mask="(99) 99999-9999"
                className="input-form"
                name="telefone"
                placeholder="Digite o telefone do contato"
                label="Telefone"
              />
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Salvar
          </button>
        </Form>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
  },
};
