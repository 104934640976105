/* eslint-disable no-sequences */
import React, {useEffect, useRef} from 'react';
import {Modal} from 'react-responsive-modal';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {Form} from '@unform/web';
import Input from '../../components/Input';
import InputMask from '../../components/InputMaks';
import api from '../../service/api';

export default function ModalAddEditContatos({
  modalVisibleAddEdit,
  contato,
  closeModal,
}) {
  const formRef = useRef(null);

  async function handleSalvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome é obrigatório'),
        telefone: Yup.string().required('Telefone é obrigatório'),
      });
      await schema.validate(data, {abortEarly: false});

      data.telefone = data.telefone.toString().replace(/\D/im, '');

      let response;
      if (contato) {
        data._id = contato._id;
        response = await api.put('/v1/contatosdb', data);
      } else {
        data.sincronizado = false;
        response = await api.post('/v1/contatosdb', data);
      }

      if (response.data.success) {
        toast.success('Contato salvo com sucesso!', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        closeModal();
      } else {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchData() {
    if (contato) {
      setTimeout(function () {
        formRef.current.setData({
          nome: contato.nome,
          telefone: contato.telefone,
        });
      }, 100);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contato]);

  return (
    <Modal
      open={modalVisibleAddEdit}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-9 col-md-9">
            <h3>Adicionar novo contato</h3>
          </div>
        </div>
        <hr />
        <Form className="mt-3" ref={formRef} onSubmit={handleSalvar}>
          <div className="row">
            <div className="col-sm-12 col-md-6 mt-2 mb-2">
              <Input
                type="text"
                className="input-form"
                name="nome"
                placeholder="Digite o nome do contato"
                label="Nome do contato"
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-2 mb-2">
              <InputMask
                type="text"
                mask="(99) 99999-9999"
                className="input-form"
                name="telefone"
                placeholder="Digite o telefone do contato"
                label="Telefone"
              />
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Salvar
          </button>
        </Form>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: '1%',
  },
};